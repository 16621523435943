
import { Vue, Component } from 'vue-property-decorator';
import ENV_SETTING from 'config';
@Component
export default class Blank extends Vue {
  copyright: string = '';
  created() {
    this.copyright = ENV_SETTING.ENV_COPYRIGHT;
  }
}
