import { Vue, Component } from 'vue-property-decorator';
import * as authHelper from '@/assets/auth_helper';
import type StaffInterface from '@/interfaces/StaffInterface';

// types.d.ts

@Component
export default class AuthMixin extends Vue {
  public getStaffToken(): string {
    let token = '';
    const staffData = authHelper.getCurrentStaffData();
    if (this.$validator.DataValid(staffData)) {
      if (this.$validator.DataValid(staffData.verify_token)) {
        token = staffData.verify_token;
      }
    }

    return token;
  }

  public getStaffUsername(): string {
    let username = 'Staff';
    const staffData = authHelper.getCurrentStaffData();
    if (this.$validator.DataValid(staffData)) {
      if (this.$validator.DataValid(staffData.username)) {
        username = staffData.username;
      }
    }

    return username;
  }

  public getStaffID(): number {
    let id = -1;
    const staffData = authHelper.getCurrentStaffData();
    if (this.$validator.DataValid(staffData)) {
      if (this.$validator.DataValid(staffData.id)) {
        id = parseInt(staffData.id);
      }
    }

    return id;
  }

  public setStaffData(staffData: StaffInterface): void {
    authHelper.setStaffCookie(staffData);
  }

  public checkStaffData(): boolean {
    let resultType = false;
    const staffData = authHelper.getCurrentStaffData();

    if (this.$validator.DataValid(staffData)) {
      if (
        this.$validator.DataValid(staffData.id) &&
        this.$validator.DataValid(staffData.last_login) &&
        this.$validator.DataValid(staffData.username) &&
        this.$validator.DataValid(staffData.verify_token)
      ) {
        resultType = true;
      }
    }

    if (!resultType) {
      this.forceLogout();
    }

    return resultType;
  }

  private async clearVerifyToken(id: number) {
    try {
      const result = await this.$XHR.api('cms_staff_logout', { id });
      this.$common.log('-----remove verify_token success-----');
    } catch (error) {
      this.$common.log('-----remove verify_token fail-----');
    } finally {
      authHelper.removeStaffCookies();
    }
  }

  public async forceLogout() {
    this.$store.dispatch('setOverlay', true);

    this.$store.dispatch('setErrorMessage', {
      message: this.$t('instruction.login-expired'),
      redirect: 'Login',
      refresh: false,
    });
  }

  public async logoutAccount() {
    this.$common.log('-----Force logout-----');
    const staffData = authHelper.getCurrentStaffData();
    this.$common.log(staffData);
    if (this.$validator.DataValid(staffData)) {
      await this.clearVerifyToken(staffData.id);
    }

    this.$router.replace({ name: 'Login' });
  }
}
