import { Vue, Component } from 'vue-property-decorator';

@Component
export default class UploadMixin extends Vue {
  public getAcceptFileFormat(type: string): any {
    let fileFormatStr = '';
    let fileFormatArr: Array<string> = [];

    if (type === 'image') {
      fileFormatStr = 'image/jpeg,image/jpg,image/png';
      fileFormatArr = ['png', 'jpeg', 'jpg'];
    } else if (type === 'video') {
      fileFormatStr = 'video/mp4,video/webm';
      fileFormatArr = ['mp4', 'webm'];
    } else if (type === 'imageWithGif') {
      fileFormatStr = 'image/jpeg,image/jpg,image/png,image/gif';
      fileFormatArr = ['png', 'jpeg', 'jpg', 'gif'];
    } else if (type === 'imageWithPDF') {
      fileFormatStr = 'image/jpeg,image/jpg,image/png,application/pdf';
      fileFormatArr = ['png', 'jpeg', 'jpg', 'pdf'];
    }

    if (this.$validator.DataValid(fileFormatStr) && this.$validator.DataValid(fileFormatArr)) {
      return {
        formatStr: fileFormatStr,
        formatArr: fileFormatArr,
      };
    }

    return null;
  }

  public checkDoubleFileExtension(fileName: string): boolean {
    const extension = fileName.match(/\.(.\S+)$/) || [];
    if (extension.length > 0) {
      if (extension[1].split('.').length !== 1) {
        return true;
      }
    }
    return false;
  }

  public validateFileInput(
    file: File,
    requiredType: string,
    requiredExtension: Array<string>,
    maxSize: number = 10,
  ): string {
    // requiredType = img, video...
    // requiredExtension = png, jpg, mp4...

    const limitFileSize = maxSize;

    let result = '';
    const fileType = file.type;
    let format = '';

    switch (fileType) {
      case 'application/msword':
        format = 'doc';
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        format = 'docx';
        break;
      case 'application/vnd.ms-excel':
        format = 'xls';
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        format = 'xlsx';
        break;
      case 'application/vnd.ms-powerpoint':
        format = 'ppt';
        break;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        format = 'pptx';
        break;
      case 'text/plain':
        format = 'txt';
        break;
      default:
        format = fileType.split('/')[1];
        break;
    }

    if (
      this.checkDoubleFileExtension(file.name) ||
      (!requiredExtension.includes('*') && !requiredExtension.includes(format))
    ) {
      result = this.$t('instruction.accept-file-type', { fileType: requiredExtension.join(', ') }).toString();
    } else if (file.size / 1000 / 1000 > limitFileSize) {
      result = this.$t('instruction.max-file-size', { fileType: limitFileSize }).toString();
    }

    return result;
  }

  public formatUploadProgress(value: number, total: number): number {
    return parseFloat(((value / total) * 100).toFixed(2));
  }

  public firstFileToBase64(fileImage: File): any {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (fileReader && fileImage != null) {
        fileReader.readAsDataURL(fileImage);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = error => {
          reject(error);
        };
      } else {
        reject(new Error('No file found'));
      }
    });
  }

  public getFileType(fileType: string): string {
    switch (fileType) {
      case 'image/jpeg':
        return 'jpg';
      case 'image/png':
        return 'png';
      case 'image/gif':
        return 'gif';
      case 'application/pdf':
        return 'pdf';
      case 'application/msword':
        return 'doc';
      case 'video/mp4':
        return 'mp4';
      case 'video/webm':
        return 'webm';

      default:
        return '';
    }
  }

  public async getUploadPayload(file: File, oldFileName: string = '') {
    const base64Path = await this.firstFileToBase64(file);
    const type = this.getFileType(file.type);

    const payload: any = { file_name: '', base64: base64Path, file_type: type };

    if (this.$validator.DataValid(oldFileName)) {
      payload.old_file_name = oldFileName;
    }

    return payload;
  }

  public async uploadFile(
    file: File,
    requiredType: string,
    requiredExtension: Array<string>,
  ): Promise<string> {
    if (
      file !== undefined &&
      file !== null &&
      this.$validator.DataValid(requiredType) &&
      this.$validator.DataValid(requiredExtension)
    ) {
      const check = this.validateFileInput(file, requiredType, requiredExtension);
      if (this.$validator.DataValid(check)) {
        this.$store.dispatch('setErrorMessage', {
          message: check,
          redirect: '',
          refresh: false,
        });
        return '';
      } else {
        let postData: any = {
          upload_image: { forceJPG: true },
          file,
        };

        if (file.type === 'application/pdf') {
          postData = {
            upload_document: {},
            file,
          };
        } else if (file.type === 'video/mp4' || file.type === 'video/webm') {
          postData = {
            upload_video: {},
            file,
          };
        }

        try {
          const upload = await this.$XHR.post(postData, (uploadProgress: any) => {
            this.$store.dispatch(
              'setUploadProgress',
              this.formatUploadProgress(uploadProgress.loaded, uploadProgress.total),
            );
          });

          this.$common.log('---upload success---');
          this.$common.log(upload);

          this.$store.dispatch('setUploadProgress', 0);
          this.$store.dispatch('setSuccessMessage', {
            message: this.$t('instruction.success-text', { name: this.$t('common.upload') }),
            redirect: '',
            refresh: false,
          });

          return upload.data.filename;
        } catch (error) {
          this.$common.log('---upload fail---');
          this.$common.log(error);

          this.$store.dispatch('setUploadProgress', 0);
          this.$store.dispatch('setErrorMessage', {
            message: this.$t('instruction.fail-text', { name: this.$t('common.upload') }),
            redirect: '',
            refresh: false,
          });
        }
      }
    } else {
      this.$store.dispatch('setErrorMessage', {
        message: this.$t('instruction.invalid-text', { name: this.$t('common.file') }),
        redirect: '',
        refresh: false,
      });
    }
    return '';
  }
}
