/** Config store */
import type { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import type { RootState } from '.';
import Cookies from 'js-cookie';

/** Config State */
export interface ConfigState {
  /** Dark Theme mode */
  themeDark: boolean;
  /** Dark Theme mode */
  miniDrawer: boolean;
  /** Language */
  locale: string;
}

declare const navigator: any;

/* const browserColorScheme: boolean =
    window.matchMedia('(prefers-color-scheme: dark)').matches ?? false; */

const browserLang: any =
  (navigator.languages ? navigator.languages[0] : navigator.language || navigator['userLanguage']) ?? '';

const cookieConfig: object = {
  path: '/',
  expires: 120,
  sameSite: 'strict',
  secure: true,
};

/** Default Configure state value */
const state: ConfigState = {
  // dark-mode
  themeDark:
    Cookies.get('quotationSystemThemeDark') !== undefined ? Cookies.get('quotationSystemThemeDark') === '1' : false,
  // mini variant Nav Drawer
  miniDrawer: false,
  // language
  locale:
    Cookies.get('quotationSystemUserLang') !== undefined
      ? Cookies.get('quotationSystemUserLang') || 'zh-HK'
      : browserLang.split('-')[0] === 'en'
      ? 'en'
      : 'zh-HK',
};

// sync to body CSS
state.themeDark ? document.body.classList.add('dark') : document.body.classList.remove('dark');

document.documentElement.setAttribute('lang', state.locale);

// refresh theme cookie
if (Cookies.get('quotationSystemThemeDark') !== undefined) {
  Cookies.set('quotationSystemThemeDark', Cookies.get('quotationSystemThemeDark') === '1' ? '1' : '0', cookieConfig);
}

/** Getters */
const getters: GetterTree<ConfigState, RootState> = {
  themeDark: (s): boolean => s.themeDark,
  miniDrawer: (s): boolean => s.miniDrawer,
  locale: (s): string => s.locale,
};

/** Mutations */
const mutations: MutationTree<ConfigState> = {
  toggleTheme(s) {
    s.themeDark = !s.themeDark;
    Cookies.set('quotationSystemThemeDark', s.themeDark ? '1' : '0', cookieConfig);
    s.themeDark ? document.body.classList.add('dark') : document.body.classList.remove('dark');
  },
  setDrawer(s, isMini: boolean) {
    s.miniDrawer = isMini;
  },
  setLocale(s, locale: string) {
    s.locale = locale;
    Cookies.set('quotationSystemUserLang', s.locale, cookieConfig);
  },
};

/** Action */
const actions: ActionTree<ConfigState, RootState> = {
  /**
   * Switch Dark/Light mode.
   *
   * @param context - Vuex Context
   */
  toggleTheme(context: ActionContext<ConfigState, RootState>) {
    context.commit('toggleTheme');
  },
  /**
   * Change nav drawer is mini or not.
   *
   * @param context - Vuex Context
   * @param isMini - Locale code
   */
  setDrawer(context: ActionContext<ConfigState, RootState>, isMini = true) {
    context.commit('setDrawer', isMini);
  },
  /**
   * Change locale.
   *
   * @param context - Vuex Context
   * @param locale - Locale code
   */
  setLocale(context: ActionContext<ConfigState, RootState>, locale = 'en') {
    context.commit('setLocale', locale);
  },
};

/** VuexStore */
const ConfigModule: Module<ConfigState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default ConfigModule;
