
import { Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import UploadMixin from '@/mixins/UploadMixin';

@Component
export default class UploadField extends mixins(UploadMixin) {
  @Prop({ type: String, required: false, default: 'upload' }) customID!: string;
  @Prop({ type: String, required: true, default: '' }) fieldValue!: string;
  @Prop({ type: String, required: true, default: '' }) acceptFormat!: string; // image / video
  @Prop({ type: Boolean, required: false, default: false }) loading!: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  showUploadButton!: Boolean;
  @Prop({ type: Boolean, required: false, default: true })
  showPreviewButton!: Boolean;
  @Prop({ type: Boolean, required: false, default: false })
  showDeleteButton!: Boolean;
  @Prop({ required: false, default: 'common.upload' }) uploadText!: String | Object;
  @Prop({ required: false, default: 'common.view' }) previewText!: String | Object;
  @Prop({ required: false, default: 'common.delete' }) deleteText!: String | Object;

  @Prop({ type: Boolean, required: false, default: true }) solo!: Boolean;
  @Prop({ type: Boolean, required: false, default: true }) filled!: Boolean;
  @Prop({ type: Boolean, required: false, default: false }) outlined!: Boolean;

  @Prop({ type: Boolean, required: false, default: true }) flat!: Boolean;
  @Prop({ type: Boolean, required: false, default: true }) dense!: Boolean;
  @Prop({ type: Boolean, required: false, default: true }) readonly!: Boolean;

  @Prop({ type: String, required: false, default: '' }) label!: string;
  @Prop({ type: String, required: false, default: '' }) prefix!: string;
  @Prop({ type: String, required: false, default: '' }) suffix!: string;
  @Prop({ type: String, required: false, default: '' }) appendIcon!: string;
  @Prop({ type: String, required: false, default: '' }) placeholder!: string;

  // validate
  @Prop({ type: Boolean, required: false, default: true }) required!: Boolean;

  // data
  textUpload: string = 'common.upload';
  textPreview: string = 'common.view';
  textDelete: string = 'common.delete';
  uploadTextParams: Object = {};
  viewTextParams: Object = {};
  deleteTextParams: Object = {};
  acceptFormatStr: string = '';
  acceptFormatArr: Array<string> = [];

  get removedImageList(): Array<string> {
    return this.$store.getters['DataModule/removedImageList'];
  }

  @Watch('uploadText')
  formatUploadParams(passData: string | Object) {
    if (typeof passData === 'string') {
      this.textUpload = passData;
      this.uploadTextParams = {};
    } else {
      this.textUpload = 'common.upload-text';
      this.uploadTextParams = passData;
    }
  }

  @Watch('previewText')
  formatPreviewParams(passData: string | Object) {
    if (typeof passData === 'string') {
      this.textPreview = passData;
      this.viewTextParams = {};
    } else {
      this.textPreview = 'common.view-text';
      this.viewTextParams = passData;
    }
  }

  @Watch('deleteText')
  formatDeleteParams(passData: string | Object) {
    if (typeof passData === 'string') {
      this.textDelete = passData;
      this.deleteTextParams = {};
    } else {
      this.textDelete = 'common.delete-text';
      this.deleteTextParams = passData;
    }
  }

  @Emit('update:loading')
  isUploading(val: boolean) {
    return val;
  }

  @Emit('update:fieldValue')
  updateFileName(fileName: string): string {
    return fileName;
  }

  @Emit('uploadImage')
  getUploadFile(fileName: string): string {
    return fileName;
  }

  // methods
  triggerFileUpload(): void {
    document.getElementById(this.customID)?.click();
  }

  async startUpload(file: File) {
    if (!this.loading) {
      this.isUploading(true);
      const upload = await this.uploadFile(file, this.acceptFormat, this.acceptFormatArr);
      if (this.$validator.DataValid(upload)) {
        this.$store.dispatch('DataModule/setRemovedImage', upload);

        this.updateFileName(upload);
        this.getUploadFile(upload);
      }
      this.isUploading(false);
    }
  }

  previewFile() {
    if (this.$validator.DataValid(this.fieldValue)) {
      window.open(this.$common.getMediaPath() + this.fieldValue);
    } else {
      this.$store.dispatch('setErrorMessage', {
        message: this.$t('instruction.not-exist-text', {
          name: this.$t('common.file'),
        }),
        redirect: '',
        refresh: false,
      });
    }
  }

  created() {
    const getFormat = this.getAcceptFileFormat(this.acceptFormat);
    if (this.$validator.DataValid(getFormat)) {
      this.acceptFormatStr = getFormat.formatStr;
      this.acceptFormatArr = getFormat.formatArr;
    }
  }
}
