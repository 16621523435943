//@ts-nocheck

import _Vue from 'vue';
import i18n from '@/plugins/i18n';

export function DataFunc(Vue: typeof _Vue): void {
  Vue.prototype.$dataFunc = {
    labelStatus: () => {
      return {
        follow_required: i18n.t('client.follow-required'),
        follow_not_required: i18n.t('client.follow-not-required'),
      };
    },

    staffRole: () => {
      return {
        master: i18n.t('staff.permission-master'),
        sales: i18n.t('staff.permission-staff'),
      };
    },

    quotationStatus: () => {
      return {
        unconfirmed: i18n.t('quotation.unconfirmed'),
        confirmed: i18n.t('quotation.confirmed'),
      };
    },

    paymentStatus: () => {
      return {
        unpaid: i18n.t('invoice.unpaid'),
        paid: i18n.t('invoice.paid'),
      };
    },
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    $dataFunc: {
      labelStatus(): Object;
      staffRole(): Object;
      quotationStatus(): Object;
      paymentStatus(): Object;
    };
  }
}
