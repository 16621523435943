
import { Vue, Component, Watch } from 'vue-property-decorator';
import LayoutBlank from '@/pages/layouts/Blank.vue';
import LayoutContent from '@/pages/layouts/Content.vue';
import { getCurrentInstance, reactive, toRefs } from '@vue/composition-api';
import type SnackItem from '@/interfaces/SnackItemInterface';

@Component({ components: { LayoutBlank, LayoutContent } })
export default class App extends Vue {
  /** Snackbar visibility */
  snackbar: Array<SnackItem> = [];
  /** Snackbar visibility */
  snackbarId: number = 0;
  /** Upload progress */
  uploadProgress: number = 0;

  trialBoxHeight: number = 0;

  year: number | string = 0;
  month: number | string = 0;
  date: number | string = 0;

  timeout: number = 3000;

  get resolveLayout(): string | null {
    let router = this.useRouter();
    if (router?.route?.value?.name === null) {
      return null;
    } else if (router?.route?.value?.meta?.layout === 'blank') {
      return 'layout-blank';
    }

    return 'layout-content';
  }

  useRouter() {
    const vm = getCurrentInstance();
    if (this.$validator.DataValid(vm)) {
      const state = reactive({
        route: vm?.proxy.$route,
      });

      return { ...toRefs(state), router: vm?.proxy.$router };
    }

    return null;
  }

  /** Error Message */
  get error(): boolean {
    return this.$store.getters.error;
  }

  /** Modify snackbar text */
  @Watch('$store.getters.message')
  onSnackbarTextChanged(): void {
    if (this.$store.getters.message !== undefined) {
      this.addSnackMessage('info', this.$store.getters.message);
      this.$store.dispatch('setMessage', undefined);
    }
  }
  /** Modify snackbar text */
  @Watch('$store.getters.successMessage')
  onSnackbarSuccessTextChanged(): void {
    if (this.$store.getters.successMessage !== undefined) {
      let message = this.$store.getters.successMessage;

      this.timeout = message.time;
      if (this.$validator.DataValid(message.top)) {
        this.addSnackMessage('success', message.message, message.redirect, message.refresh, true);
      } else {
        this.addSnackMessage('success', message.message, message.redirect, message.refresh);
      }

      this.$store.dispatch('setSuccessMessage', undefined);
    }
  }
  /** Modify snackbar text */
  @Watch('$store.getters.errorMessage')
  onSnackbarErrorTextChanged(): void {
    if (this.$store.getters.errorMessage !== undefined) {
      let message = this.$store.getters.errorMessage;

      this.timeout = message.time;
      if (this.$validator.DataValid(message.top)) {
        this.addSnackMessage('error', message.message, message.redirect, message.refresh, true);
      } else {
        this.addSnackMessage('error', message.message, message.redirect, message.refresh);
      }

      this.$store.dispatch('setErrorMessage', undefined);
    }
  }
  /** Modify snackbar text */
  @Watch('$store.getters.warnMessage')
  onSnackbarWarnTextChanged(): void {
    if (this.$store.getters.warnMessage !== undefined) {
      this.addSnackMessage('warning', this.$store.getters.warnMessage);
      this.$store.dispatch('setWarnMessage', undefined);
    }
  }

  /** Get Upload Progress */
  @Watch('$store.getters.uploadProgress')
  onUploadProgressChange(): void {
    if (this.$store.getters.uploadProgress !== undefined) {
      this.uploadProgress = this.$store.getters.uploadProgress;
    }
  }

  /** Get UI overlay visibility */
  get overlay(): boolean {
    return this.$store.getters.overlay;
  }
  /**
   * Set UI overlay
   * @param value - Visibility
   */
  set overlay(value: boolean) {
    this.$store.dispatch('setOverlay', value);
  }

  /** add a snackbar message to queue */
  addSnackMessage(level = 'info', text = '', redirect = '', refresh = false, top = false): void {
    this.snackbarId++;

    let snackbar = {
      enabled: true,
      id: this.snackbarId,
      level,
      text,
      redirectLocation: redirect,
      refresh,
      top,
    };

    this.snackbar.push(snackbar);
  }

  /** remove disabled snack message */
  updateSnackbar(id: number): void {
    const vm = this;
    setTimeout(() => {
      const bar = vm.snackbar.filter(e => e.id === id);
      if (bar.length > 0) {
        vm.timeout = 3000;

        // refresh / go to other page
        if (this.$validator.DataValid(bar[0].redirectLocation)) {
          this.$store.dispatch('setOverlay', false);

          if (bar[0].redirectLocation === 'Login') {
            this.logoutAccount();
          } else {
            this.$router.replace({ name: bar[0].redirectLocation });
          }
        } else if (bar[0].refresh) {
    
          this.$store.dispatch('setOverlay', false);
          this.$router.go(0);
        }
      }
      vm.snackbar = vm.snackbar.filter(e => e.id !== id);
    }, 500);
  }
}
