
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { mdiEye, mdiEyeOff } from '@mdi/js';

@Component
export default class TextInput extends Vue {
  @Prop({ required: true, default: '' }) fieldValue!: string | number;

  @Prop({ type: Boolean, required: false, default: true }) solo!: Boolean;
  @Prop({ type: Boolean, required: false, default: false }) filled!: Boolean;
  @Prop({ type: Boolean, required: false, default: false }) outlined!: Boolean;

  @Prop({ type: Boolean, required: false, default: true }) flat!: Boolean;
  @Prop({ type: Boolean, required: false, default: true }) dense!: Boolean;
  @Prop({ type: Boolean, required: false, default: false }) readonly!: Boolean;
  @Prop({ type: Boolean, required: false, default: false }) disabled!: Boolean;

  @Prop({ type: String, required: false, default: '' }) label!: string;
  @Prop({ type: String, required: false, default: '' }) subLabel!: string;
  @Prop({ type: String, required: false, default: '' }) horizontalLabel!: string;
  @Prop({ type: String, required: false, default: 'text' }) type!: string;

  @Prop({ type: String, required: false, default: '' }) prefix!: string;
  @Prop({ type: String, required: false, default: '' }) suffix!: string;
  @Prop({ type: Object, required: false, default: () => {} }) customClass!: any;

  @Prop({ type: String, required: false, default: '' })
  prependInnerIcon!: string;
  @Prop({ type: String, required: false, default: '' }) appendIcon!: string;
  @Prop({ type: String, required: false, default: '' }) placeholder!: string;
  @Prop({ type: Boolean, required: false, default: false })
  showScanBtn!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  showPWBtn!: boolean;

  @Prop({ type: Boolean, required: false, default: false }) showSettingBtn!: boolean;

  @Prop({ type: String, required: false, default: '' }) tooltipText!: string;

  @Prop({ type: Boolean, required: false, default: false }) counter!: Boolean;
  @Prop({ type: Number, required: false, default: 70 }) maxCounter!: number;

  @Prop({ type: String, required: false, default: '' }) hint!: string;

  // validate
  @Prop({ type: Boolean, required: false, default: false }) required!: Boolean;
  @Prop({ type: Boolean, required: false, default: false })
  validateEmail!: Boolean;
  @Prop({ type: Boolean, required: false, default: false })
  validatePhone!: Boolean;
  @Prop({ type: Boolean, required: false, default: false })
  validateFloating!: Boolean;
  @Prop({ type: Boolean, required: false, default: false })
  validateNumber!: Boolean;
  @Prop({ type: Boolean, required: false, default: false })
  validateNegativeNumber!: Boolean;
  @Prop({ type: Boolean, required: false, default: false })
  validateAlphaNumeric!: Boolean;
  @Prop({ type: Boolean, required: false, default: false })
  validateURLSlug!: Boolean;
  @Prop({ type: Boolean, required: false, default: false })
  validateCustomPageLink!: Boolean;
  // when the checking accept empty input
  @Prop({ type: Boolean, required: false, default: false }) allowEmptyValidate!: Boolean;

  @Prop({ type: String, required: false, default: '' })
  customErrorText!: string;
  @Prop({ type: Array, required: false, default: () => [] })
  customChecking!: Array<Function | boolean | string>;
  @Prop({ type: Boolean, required: false, default: false }) customErrorState!: Boolean;

  // Format
  @Prop({ type: Boolean, required: false, default: false })
  inputNumberOnly!: Boolean;
  @Prop({ type: Boolean, required: false, default: false })
  inputFloatingOnly!: Boolean;
  @Prop({ type: Boolean, required: false, default: false })
  inputAlphaNumericOnly!: Boolean;
  @Prop({ type: Boolean, required: false, default: false })
  inputURLSlug!: Boolean;
  @Prop({ type: Boolean, required: false, default: false })
  inputCustomPageLink!: Boolean;
  @Prop({ type: Array, required: false, default: () => [] })
  inputCustomFormat!: Array<any>;

  // activators
  @Prop({ required: false, type: Object, default: () => {} }) activator: any;
  @Prop({ required: false, type: Object, default: () => {} }) attrs: any;

  // data
  updateType: string = '';
  showPWText: boolean = false;
  mdiEye: string = mdiEye;
  mdiEyeOff: string = mdiEyeOff;

  textInput: string | number = '';

  // Watch
  @Watch('type', { immediate: true })
  setType(val: string) {
    this.updateType = val;
  }

  @Watch('fieldValue', { immediate: true })
  setValue(val: string | number) {
    this.textInput = val;
  }

  // Emit
  @Emit('update:fieldValue')
  updateValue(val: string | number) {
    if (typeof val === 'string') {
      return val.trim();
    }

    return val;
  }

  @Emit('getSearchValue')
  getSearchValue(val: string | number) {
    if (typeof this.fieldValue === 'string') {
      return this.fieldValue.trim();
    }

    return this.fieldValue;
  }

  @Emit('clickSettingBtn')
  clickSettingBtn() {
    return true;
  }

  // methods
  formatInput(event: any) {
    if (this.inputNumberOnly) {
      return this.$formatter.number(event);
    } else if (this.inputFloatingOnly) {
      return this.$formatter.floating(event);
    } else if (this.inputAlphaNumericOnly) {
      return this.$formatter.alphaNumeric(event);
    } else if (this.inputURLSlug) {
      return this.$formatter.urlFilter(event);
    } else if (this.inputCustomPageLink) {
      return this.$formatter.customPageLinkFilter(event);
    } else if (this.$validator.DataValid(this.inputCustomFormat)) {
      return this.$formatter.customFormatFilter(event, this.inputCustomFormat);
    }
  }

  getCounterVal(val: string) {
    return this.$t('user.counter-str', {
      currentCount: val.trim().length,
      maxCount: this.maxCounter,
    });
  }

  togglePWVisibility() {
    this.showPWText = !this.showPWText;

    if (this.showPWText) {
      this.updateType = 'text';
    } else {
      this.updateType = 'password';
    }
  }

  keyUpEvent(event: any) {
    if (this.inputNumberOnly) {
      if (
        event.keyCode === 8 ||
        (event.keyCode >= 37 && event.keyCode <= 40) ||
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105)
      ) {
        this.updateValue(this.textInput);
      } else {
        setTimeout(() => {
          const formattedText: any = this.textInput.toString().match(new RegExp(this.$validator.regex().pasteNumber));

          if (this.$validator.DataValid(formattedText)) {
            if (formattedText.length > 0) {
              this.textInput = parseFloat(formattedText[0]);

              this.updateValue(this.textInput);
            }
          } else {
            this.textInput = 0;
            this.updateValue(this.textInput);
          }
        }, 100);
      }
    } else if (this.inputFloatingOnly) {
      if (
        event.keyCode === 8 ||
        event.keyCode === 110 ||
        event.keyCode === 190 ||
        (event.keyCode >= 37 && event.keyCode <= 40) ||
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105)
      ) {
        this.updateValue(this.textInput);
      } else {
        setTimeout(() => {
          const formattedText: any = this.textInput.toString().match(new RegExp(this.$validator.regex().pasteFloating));

          if (this.$validator.DataValid(formattedText)) {
            if (formattedText.length > 0) {
              this.textInput = parseFloat(formattedText[0]);

              this.updateValue(this.textInput);
            }
          } else {
            this.textInput = 0;
            this.updateValue(this.textInput);
          }
        }, 100);
      }
    } else {
      this.updateValue(this.textInput);
    }
  }
}
