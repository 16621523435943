//@ts-nocheck

import _Vue from 'vue';
import store from '@/store';
import i18n from '@/plugins/i18n';
import ENV_SETTING from 'config';

export function CommonFunc(Vue: typeof _Vue): void {
  const mediaPath = process.env.NODE_ENV === 'production' ? ENV_SETTING.ENV_MEDIA_PATH !== undefined &&
    ENV_SETTING.ENV_MEDIA_PATH !== null &&
    ENV_SETTING.ENV_MEDIA_PATH !== ''
    ? ENV_SETTING.ENV_MEDIA_PATH : '/media/' : '/media/';

  // https://stackoverflow.com/questions/9083037/convert-a-number-into-a-roman-numeral-in-javascript
  let romanMatrix = [
    [1000, 'M'],
    [900, 'CM'],
    [500, 'D'],
    [400, 'CD'],
    [100, 'C'],
    [90, 'XC'],
    [50, 'L'],
    [40, 'XL'],
    [10, 'X'],
    [9, 'IX'],
    [5, 'V'],
    [4, 'IV'],
    [1, 'I']
  ];

  const convertToRoman = (num: number) => {
    if (num === 0) {
      return '';
    }
    for (var i = 0; i < romanMatrix.length; i++) {
      if (num >= romanMatrix[i][0]) {
        return romanMatrix[i][1].toLowerCase() + convertToRoman(num - romanMatrix[i][0]);
      }
    }
  }

  const replaceTabString = (str: string) => {
    return str.replaceAll("\t", "　")
  }

  const getMarginTopAndBottom = (dom: Element) => {
    const result = { top: 0, bottom: 0 };
    const computedStyle = getComputedStyle(dom);

    let marginTop = computedStyle.marginTop.split("px");
    if (marginTop.length > 0) {
      if (!isNaN(marginTop[0])) {
        result.top = parseFloat(marginTop[0]);
      }
    }

    let marginBottom = computedStyle.marginBottom.split("px");
    if (marginBottom.length > 0) {
      if (!isNaN(marginBottom[0])) {
        result.bottom = parseFloat(marginBottom[0]);
      }
    }

    return result;
  }

  const log = (value: any) => {
    const currentLocation = window.location.hostname
    if (currentLocation.includes('localhost') || currentLocation.includes(
      'innpressionhost')) {
      console.log(value);
    }
  }

  const formatPDFListCSS = (listDom: Element, listEndNewLine: boolean = false) => {
    let result = "";
    let dots = "•";

    let firstLayerCount = 1;
    let maxLayerCount = 0;

    let innerLayerCount = [];

    let previousLayer = 1;
    for (let k = 0; k < listDom.children.length; k++) {
      const listNode = listDom.children[k];

      let indentLevel = -1;
      for (let y = 0; y < listNode.classList.length; y++) {
        if (new RegExp(/^(ql\-indent\-[1-8]){1}$/i).test(listNode.classList[y])) {
          indentLevel = parseInt(listNode.classList[y].substr(listNode.classList[y].length - 1));
          break;
        }
      }

      if (indentLevel === -1) {
        let marker = dots;

        if (listDom.tagName === 'OL') {
          marker = `${firstLayerCount}.`;
        }

        if (listDom.children[k].children.length > 0) {
          let textContent = replaceTabString(listDom.children[k].children[0].textContent);

          result += `${marker}　${textContent}`;
        } else {
          result += `${marker}　${replaceTabString(listNode.textContent)}`;
        }

        firstLayerCount++;

        innerLayerCount = [];
        for (let y = 0; y < maxLayerCount.length; y++) {
          innerLayerCount.push(1);
        }

        previousLayer = 1;
      } else {

        if (maxLayerCount < indentLevel) {
          maxLayerCount = indentLevel;
        }

        if (innerLayerCount.length < maxLayerCount) {
          const remaining = maxLayerCount - innerLayerCount.length;
          for (let y = 1; y <= remaining; y++) {
            innerLayerCount.push(1)
          }
        }

        if (previousLayer !== indentLevel) {
          if (indentLevel < previousLayer) {
            for (let y = previousLayer - 1; y < innerLayerCount.length; y++) {
              innerLayerCount[y] = 1;
            }
          }
        }

        previousLayer = indentLevel;

        let marker = dots;

        if (indentLevel % 3 === 1) {
          if (listDom.tagName === 'OL') {
            marker = `${String.fromCharCode(innerLayerCount[indentLevel - 1] + 96)}.`;
          }

          for (let i = 0; i < indentLevel; i++) {
            result += "　　";
          }

          if (listDom.children[k].children.length > 0) {
            let textContent = replaceTabString(listDom.children[k].children[0].textContent);

            result += `${marker}　${textContent}`;
          } else {
            result += `${marker}　${replaceTabString(listNode.textContent)}`;
          }


          innerLayerCount[indentLevel - 1]++;
        } else if (indentLevel % 3 === 2) {
          if (listDom.tagName === 'OL') {
            marker = `${convertToRoman(innerLayerCount[indentLevel - 1])}.`
          }

          for (let i = 0; i < indentLevel; i++) {
            result += "　　";
          }

          if (listDom.children[k].children.length > 0) {
            let textContent = replaceTabString(listDom.children[k].children[0].textContent);

            result += `${marker}　${textContent}`;
          } else {
            result += `${marker}　${replaceTabString(listNode.textContent)}`;
          }

          innerLayerCount[indentLevel - 1]++;
        } else if (indentLevel % 3 === 0) {
          if (listDom.tagName === 'OL') {
            marker = `${innerLayerCount[indentLevel - 1]}.`
          }

          for (let i = 0; i < indentLevel; i++) {
            result += "　　";
          }

          if (listDom.children[k].children.length > 0) {
            let textContent = replaceTabString(listDom.children[k].children[0].textContent);

            result += `${marker}　${textContent}`;
          } else {
            result += `${marker}　${replaceTabString(listNode.textContent)}`;
          }

          innerLayerCount[indentLevel - 1]++;
        }

      }

      if (listEndNewLine) {
        result += "\n";
      } else {
        if (k + 1 !== listDom.children.length) {
          result += "\n";
        }
      }


    }

    return result;
  }



  const recursiveFormatPDFTableHTML = (dom: Element, listEndNewLine: boolean = false) => {
    let result = "";
    if (["H1", "H2", "H3", "H4", "H5", "H6"].includes(dom.tagName)) {
      result += replaceTabString(dom.textContent) + "\n";
    } else if (dom.tagName === "UL" || dom.tagName === "OL") {
      result += formatPDFListCSS(dom, listEndNewLine);
    } else if (dom.tagName === "P") {
      if (dom.children.length <= 0) {
        result += replaceTabString(dom.textContent) + "\n";
      } else if (dom.children.length === 1 && dom.children[0].tagName === "BR") {
        result += "\n";
      } else {
        for (let i = 0; i < dom.children.length; i++) {
          result += recursiveFormatPDFTableHTML(dom.children[i], listEndNewLine);
        }
      }
    } else if (dom.tagName === "IMG" || dom.tagName === "A") {

    } else {
      result += replaceTabString(dom.textContent) + "\n";
    }

    return result;
  }

  Vue.prototype.$common = {
    log(value: any): void {
      log(value);
    },
    getMediaPath(): string {
      return mediaPath;
    },
    containsInvalidCharacters(x: string): boolean {
      let regex = new RegExp("[^A-Za-z0-9-_.!~*'() %\u4E00-\u9FFF]");
      return regex.test(x);
    },
    copyText(text: string): void {
      navigator.clipboard.writeText(text).then(
        () => {
          store.dispatch('setSuccessMessage', {
            message: i18n.t('instruction.success-text', { name: i18n.t('common.copy') }),
            redirect: '',
            refresh: false,
          });
        },
        () => {
          store.dispatch('setErrorMessage', {
            message: i18n.t('instruction.fail-text', { name: i18n.t('common.copy') }),
            redirect: '',
            refresh: false,
          });
        },
      );
    },
    calculateTableActionIndex(index: number, currentLimit: number, currentPage: number): number {
      if (currentLimit === -1) {
        return index;
      } else {
        return (currentPage - 1) * currentLimit + index;
      }
    },
    isChrome(): boolean {
      return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime); // Chrome 1 - 79
    },
    isFirefox(): boolean {
      return typeof InstallTrigger !== 'undefined'; // Firefox 1+
    },
    isIE(): boolean {
      const ua = navigator.userAgent;
      return document.documentMode && !/Edge/.test(ua); // IE 6-11
    },
    isEdge(): boolean {
      return !isIE && !!window.StyleMedia; // Edge 20+
    },
    isSafari(): boolean {
      return (
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === '[object SafariRemoteNotification]';
        })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification))
      ); // Safari 3.0+ "[object HTMLElementConstructor]"
    },
    getCurrentLang(zh_name: string, en_name: string = '', cn_name: string = '') {
      let result = zh_name;
      const lang = i18n.locale;

      if (lang === 'en') {
        if (en_name !== undefined && en_name !== null && en_name !== '') {
          result = en_name;
        }
      }
      if (lang === 'cn') {
        if (cn_name !== undefined && cn_name !== null && cn_name !== '') {
          result = cn_name;
        }
      }

      return result;
    },
    getMediaInsideHTMLString(htmlStr: string): Array<string> {
      let arr = [];

      const dom = new DOMParser().parseFromString(htmlStr, 'text/html');

      const imgTags = dom.getElementsByTagName('img');

      for (let i = 0; i < imgTags.length; i++) {
        const fullPath = imgTags[i].getAttribute('src').trim();

        arr.push(fullPath.substring(fullPath.indexOf('/media/') + 7));
      }

      const iframeTags = dom.getElementsByTagName('iframe');
      for (let i = 0; i < iframeTags.length; i++) {
        const fullPath = iframeTags[i].getAttribute('src').trim();

        arr.push(fullPath.substring(fullPath.indexOf('/media/') + 7));
      }

      return arr;
    },
    formatPDFLogo(originWidth: number, originHeight: number) {
      const result = { smallLogoHeight: 0, smallLogoWidth: 0, mainLogoHeight: 0, mainLogoWidth: 0, canvasLogoHeight: 0, canvasLogoWidth: 0, smallCanvasLogoHeight: 0, smallCanvasLogoWidth: 0, };

      if (originHeight > 0 && originWidth > 0) {
        if (originWidth > originHeight) {
          result.mainLogoWidth = 300
          result.mainLogoHeight = (originHeight * 300) / originWidth

          result.smallLogoWidth = 200
          result.smallLogoHeight = (originHeight * 200) / originWidth

        } else if (originWidth < originHeight) {
          result.mainLogoHeight = 150
          result.mainLogoWidth = (originWidth * 150) / originHeight

          result.smallLogoHeight = 100
          result.smallLogoWidth = (originWidth * 100) / originHeight

        } else {
          result.mainLogoWidth = 125
          result.mainLogoHeight = 125

          result.smallLogoWidth = 100
          result.smallLogoHeight = 100
        }
      }

      //if (originHeight >= 800 || originWidth >= 800) {
      result.canvasLogoHeight = result.mainLogoHeight;
      result.canvasLogoWidth = result.mainLogoWidth;
      result.smallCanvasLogoHeight = result.smallLogoHeight;
      result.smallCanvasLogoWidth = result.smallLogoWidth
      //}

      const mmUnit = 0.2645833333;

      result.mainLogoWidth *= mmUnit;
      result.mainLogoHeight *= mmUnit;
      result.smallLogoWidth *= mmUnit;
      result.smallLogoHeight *= mmUnit;

      return result;
    },
    formatPDFChop(originWidth: number, originHeight: number) {
      const result = { mainLogoHeight: 0, mainLogoWidth: 0, canvasLogoHeight: 0, canvasLogoWidth: 0, };

      if (originHeight > 0 && originWidth > 0) {
        if (originWidth > originHeight) {
          result.mainLogoWidth = 75
          result.mainLogoHeight = (originHeight * 75) / originWidth
        } else if (originWidth < originHeight) {
          result.mainLogoHeight = 75
          result.mainLogoWidth = (originWidth * 75) / originHeight
        } else {
          result.mainLogoWidth = 75
          result.mainLogoHeight = 75
        }
      }

      if (originHeight >= 800 || originWidth >= 800) {
        result.canvasLogoHeight = result.mainLogoHeight;
        result.canvasLogoWidth = result.mainLogoWidth;

      }

      const mmUnit = 0.2645833333;

      result.mainLogoWidth *= mmUnit;
      result.mainLogoHeight *= mmUnit;


      return result;
    },
    formatPDFSign(originWidth: number, originHeight: number) {
      const result = { mainLogoHeight: 0, mainLogoWidth: 0, canvasLogoHeight: 0, canvasLogoWidth: 0, };

      if (originHeight > 0 && originWidth > 0) {
        if (originWidth > originHeight) {
          result.mainLogoWidth = 250
          result.mainLogoHeight = (originHeight * 250) / originWidth
        } else if (originWidth < originHeight) {
          result.mainLogoHeight = 100
          result.mainLogoWidth = (originWidth * 100) / originHeight
        } else {
          result.mainLogoWidth = 100
          result.mainLogoHeight = 100
        }
      }

      if (originHeight >= 800 || originWidth >= 800) {
        result.canvasLogoHeight = result.mainLogoHeight;
        result.canvasLogoWidth = result.mainLogoWidth;

      }

      const mmUnit = 0.2645833333;

      result.mainLogoWidth *= mmUnit;
      result.mainLogoHeight *= mmUnit;


      return result;
    },


    convertToRoman(num: number) {
      return convertToRoman(num);
    },
    formatPDFListCSS(content: string) {
      let result = content;
      let dots = "•";

      if (content !== undefined && content !== null && content != '') {
        let dom = document.createElement('div');
        dom.innerHTML = content;

        for (let i = 0; i < dom.childNodes.length; i++) {
          const searchNodes = dom.childNodes[i];

          if (searchNodes.tagName === 'OL' || searchNodes.tagName === 'UL') {
            let firstLayerCount = 1;
            let maxLayerCount = 0;

            let innerLayerCount = [];

            let previousLayer = 1;

            for (let k = 0; k < searchNodes.children.length; k++) {
              const listNode = searchNodes.children[k];

              let indentLevel = -1;
              for (let y = 0; y < listNode.classList.length; y++) {
                if (new RegExp(/^(ql\-indent\-[1-8]){1}$/i).test(listNode.classList[y])) {
                  indentLevel = parseInt(listNode.classList[y].substr(listNode.classList[y].length - 1));
                  break;
                }
              }

              if (indentLevel === -1) {
                let marker = dots;

                if (searchNodes.tagName === 'OL') {
                  marker = `${firstLayerCount}.`;
                }

                if (dom.children[i].children[k].children.length > 0) {
                  let textContent = dom.children[i].children[k].children[0].textContent;

                  dom.children[i].children[k].children[0].textContent = `${marker}　${textContent}`;
                } else {
                  dom.children[i].children[k].textContent = `${marker}　${listNode.textContent}`;
                }

                firstLayerCount++;

                innerLayerCount = [];
                for (let y = 0; y < maxLayerCount.length; y++) {
                  innerLayerCount.push(1);
                }

                previousLayer = 1;
              } else {

                if (maxLayerCount < indentLevel) {
                  maxLayerCount = indentLevel;
                }

                if (innerLayerCount.length < maxLayerCount) {
                  const remaining = maxLayerCount - innerLayerCount.length;
                  for (let y = 1; y <= remaining; y++) {
                    innerLayerCount.push(1)
                  }
                }

                if (previousLayer !== indentLevel) {
                  if (indentLevel < previousLayer) {
                    for (let y = previousLayer - 1; y < innerLayerCount.length; y++) {
                      innerLayerCount[y] = 1;
                    }
                  }
                }

                previousLayer = indentLevel;

                let marker = dots;

                if (indentLevel % 3 === 1) {
                  if (searchNodes.tagName === 'OL') {
                    marker = `${String.fromCharCode(innerLayerCount[indentLevel - 1] + 96)}.`;
                  }

                  if (dom.children[i].children[k].children.length > 0) {
                    let textContent = dom.children[i].children[k].children[0].textContent;

                    dom.children[i].children[k].children[0].textContent = `${marker}　${textContent}`;
                  } else {
                    dom.children[i].children[k].textContent = `${marker}　${listNode.textContent}`;
                  }

                  dom.children[i].children[k].style.marginLeft = `${indentLevel * 22}px`
                  //dom.children[i].children[k].classList.add("layer-1")

                  innerLayerCount[indentLevel - 1]++;
                } else if (indentLevel % 3 === 2) {
                  if (searchNodes.tagName === 'OL') {
                    marker = `${convertToRoman(innerLayerCount[indentLevel - 1])}.`
                  }

                  if (dom.children[i].children[k].children.length > 0) {
                    let textContent = dom.children[i].children[k].children[0].textContent;

                    dom.children[i].children[k].children[0].textContent = `${marker}　${textContent}`;
                  } else {
                    dom.children[i].children[k].textContent = `${marker}　${listNode.textContent}`;
                  }

                  dom.children[i].children[k].style.marginLeft = `${indentLevel * 22}px`
                  //dom.children[i].children[k].classList.add("layer-2")

                  innerLayerCount[indentLevel - 1]++;
                } else if (indentLevel % 3 === 0) {
                  if (searchNodes.tagName === 'OL') {
                    marker = `${innerLayerCount[indentLevel - 1]}.`
                  }

                  if (dom.children[i].children[k].children.length > 0) {
                    let textContent = dom.children[i].children[k].children[0].textContent;

                    dom.children[i].children[k].children[0].textContent = `${marker}　${textContent}`;
                  } else {
                    dom.children[i].children[k].textContent = `${marker}　${listNode.textContent}`;
                  }

                  dom.children[i].children[k].style.marginLeft = `${indentLevel * 22}px`
                  //dom.children[i].children[k].classList.add("layer-3")

                  innerLayerCount[indentLevel - 1]++;
                }
              }
            }
          }
        }

        result = dom.innerHTML;
      }


      return result;

    },

    formatPDFTableHTML(htmlStr: string, listEndNewLine: boolean = false) {
      let result = "";

      if (htmlStr !== undefined && htmlStr !== null && htmlStr !== '') {
        let parentDom = document.createElement("div");
        parentDom.innerHTML = htmlStr;
        for (let i = 0; i < parentDom.children.length; i++) {
          const pChild = parentDom.children[i];

          result += recursiveFormatPDFTableHTML(pChild, listEndNewLine);
        }
      }

      return result;
    },

  };
}

declare module 'vue/types/vue' {
  interface Vue {
    $common: {
      log(value: any): void;
      getMediaPath(): string;
      containsInvalidCharacters(x: string): boolean;
      copyText(text: string): void;
      calculateTableActionIndex(index: number, currentLimit: number, currentPage: number): number;
      isChrome(): boolean;
      isFirefox(): boolean;
      isIE(): boolean;
      isEdge(): boolean;
      isSafari(): boolean;
      getCurrentLang(zh_name: string, en_name: string, cn_name: string): string;
      getMediaInsideHTMLString(htmlStr: string): Array<string>;
      convertToRoman(num: number);
      formatPDFListCSS(content: string);
      formatPDFLogo(originWidth: number, originHeight: number);
      formatPDFChop(originWidth: number, originHeight: number);
      formatPDFSign(originWidth: number, originHeight: number);
      formatPDFTableHTML(htmlStr: string, listEndNewLine: boolean = false);
    };
  }
}
