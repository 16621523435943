/** Config store */
import type { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import type { RootState } from '.';

export interface DataState {
  removedImageList: Array<string>;
}

const state: DataState = {
  removedImageList: [],
};

const getters: GetterTree<DataState, RootState> = {
  removedImageList: (s): Array<string> => s.removedImageList,
};

const mutations: MutationTree<DataState> = {
  updateRemovedImage: function (state, data) {
    state.removedImageList.push(data);
  },

  updateRemovedImageList: function (state, data) {
    state.removedImageList = state.removedImageList.concat(data);
  },

  resetRemovedImage: function (state, data = []) {
    state.removedImageList = data;
  },
};

const actions: ActionTree<DataState, RootState> = {
  setRemovedImage({ commit }, data) {
    commit('updateRemovedImage', data);
  },

  setRemovedImageList({ commit }, data) {
    commit('updateRemovedImageList', data);
  },

  resetRemovedImage({ commit }, data = []) {
    commit('resetRemovedImage', data);
  },
};

const DataModule: Module<DataState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default DataModule;
