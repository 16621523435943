
import type SelectItemInterface from '@/interfaces/SelectItemInterface';
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class SelectField extends Vue {
  @Prop({ type: Array, required: true, default: () => [] })
  itemList!: Array<SelectItemInterface>;
  @Prop({ required: true, default: '' }) fieldValue!: string | number;
  @Prop({ required: false, default: null })
  valueComparator!: Function | null;

  @Prop({ type: Boolean, required: false, default: true }) solo!: Boolean;
  @Prop({ type: Boolean, required: false, default: false }) filled!: Boolean;
  @Prop({ type: Boolean, required: false, default: false }) outlined!: Boolean;

  @Prop({ type: Boolean, required: false, default: true }) flat!: Boolean;
  @Prop({ type: Boolean, required: false, default: true }) dense!: Boolean;
  @Prop({ type: Boolean, required: false, default: false }) readonly!: Boolean;
  @Prop({ type: Boolean, required: false, default: false }) disabled!: Boolean;
  @Prop({ type: Boolean, required: false, default: false }) multiple!: Boolean;
  @Prop({ type: Boolean, required: false, default: false }) allowSelection!: Boolean;
  @Prop({ type: Boolean, required: false, default: false }) enableClearFilter!: Boolean;

  @Prop({ type: String, required: false, default: '' }) label!: string;
  @Prop({ type: String, required: false, default: '' }) placeholder!: string;
  @Prop({ type: String, required: false, default: '' }) tooltipText!: string;

  @Prop({ type: String, required: false, default: '' }) prefix!: string;
  @Prop({ type: String, required: false, default: '' }) suffix!: string;
  @Prop({ type: Object, required: false, default: () => {} }) customClass!: any;

  @Prop({ type: Boolean, required: false, default: false }) required!: Boolean;

  @Prop({ type: Function, required: false, default: () => {} })
  customInputFunc!: Function;

  @Prop({ type: Function, required: false, default: () => {} })
  customClickFunc!: Function;

  @Emit('update:fieldValue')
  updateValue(val: string | number) {
    return val;
  }

  @Emit('filter-cleared')
  clearFilter(): string {
    return '';
  }
}
